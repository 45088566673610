export const SET_LANG = 'SET_LANG';
export const SET_BASE = 'SET_BASE';
export const SET_VMODE = 'SET_VMODE';
//export const SET_FOLDER = 'SET_FOLDER';

export const LOAD_NAV_IDS = 'LOAD_NAV_IDS';
export const LOAD_MANUFACTUR = 'LOAD_MANUFACTUR';


//export const SET_NAV_ID = 'SET_NAV_ID';
export const SET_CUR_ID = 'SET_CUR_ID';
export const SET_SELECTED_ID = 'SET_SELECTED_ID';
export const SET_MENU_PTR_ID = 'SET_MENU_PTR_ID';


export const SET_MENU_PUNKT_PBL_TAG = 'SET_MENU_PUNKT_PBL_TAG';
export const SET_MENU_PUNKT_VMODE_TAG = 'SET_MENU_PUNKT_VMODE_TAG';
export const SET_MENU_PUNKT_ID_TAG = 'SET_MENU_PUNKT_ID_TAG';
export const SET_MENU_PUNKT_TTL_TAG = 'SET_MENU_PUNKT_TTL_TAG';


export const SET_NO_PNG = 'SET_NO_PNG';

export const SET_MENU_FORSE_RELOAD_FLAG = 'SET_MENU_FORSE_RELOAD_FLAG';

export const SET_MENU_LOAD_FUNC = 'SET_MENU_LOAD_FUNC';

export const SET_DATA_STRUCT_PTR = 'SET_DATA_STRUCT_PTR';
export const SET_DATA_STRUCT_ENTERING_PTR = 'SET_DATA_STRUCT_ENTERING_PTR';
export const SET_MENU_PTR = 'SET_MENU_PTR';
export const SET_MENU_LIST = 'SET_MENU_LIST';
export const DND_SET_MENU_LIST = 'DND_SET_MENU_LIST';


export const SET_DATA_STRUCT_FLAG = 'SET_DATA_STRUCT_FLAG';
export const SET_ACTION = 'SET_ACTION';


export const LOCALHOST = 'LOCALHOST';
export const CONDTROL_RU = 'CONDTROL_RU';
export const INDELTA_RU = 'INDELTA_RU';


export const UPDATE_VCONFIG_CONFIG = 'UPDATE_VCONFIG_CONFIG';
export const UPDATE_VCONFIG_VMODE = 'UPDATE_VCONFIG_VMODE';


export const LOAD_CONTENT = 'LOAD_CONTENT';
export const SET_CONTENT_IS_PUBLISHED = 'SET_CONTENT_IS_PUBLISHED';
export const SET_CONTENT_IMGES = 'SET_CONTENT_IMGES';
export const SET_CONTENT_HTML_TTL = 'SET_CONTENT_HTML_TTL';

export const TOGLE_CONTENT_ZAK_TAG = 'TOGLE_CONTENT_ZAK_TAG';
export const SET_IMAGE_LIST = 'SET_IMAGE_LIST';

export const SMODE = 'SMODE';




