import React, { Fragment, useState, useEffect } from 'react';
import GetInfoBlock from "./GetInfoBlock"
import FormSolutions from './FormSolutions'


export default function Service(props){

    var info_id = [];

    info_id['de'] = 'service';
    info_id['en'] = 'service';
    info_id['ru'] = 'service';
    info_id['uz'] = 'service';

    let lang = props.lang;


    return (
      <Fragment>
        <div className="pages">
          <GetInfoBlock blk={info_id[lang]} ttl="show"/>
          <FormSolutions lang={lang} page='SERVICE'/>
        </div>
      </Fragment>        
    );

}