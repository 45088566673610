import React, { useState, useEffect } from 'react';

export default function useMedia(query){
  let [matches,setMatches] = useState( window.matchMedia(query).matches );
useEffect(()=>{
  let media = window.matchMedia(query);
  if(media.matches !== matches) {setMatches(media.matches)};
  let listner = () => setMatches(media.matches);
  media.addListener(listner);
  return () => media.removeListener(listner);
}, [query] );
return matches;
}