import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Base64 from "./Base64"
import Loader from "./Loader"
import './css/picspanel.css'
import { Link, useParams } from "react-router-dom";


export default function PicsPanel(props){

const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [data,setData] = useState([]);

//console.log('COUNT:',count);

const id = "picsinfo";
const base = 'info';

useEffect(() => {
  GetDATA(id, bases[base]); 
}, [id] );

var qstr = window.location.toString();
let url = new URL(qstr);
let ltmp= url.pathname.split('/');
var lang = ltmp[1];



//============================================================

if(AllIs.error){
        return <p>INFO BLOCK: {AllIs.error.message}</p>
    } 

if(AllIs.loading){ return <Loader/> } 


//const menu = {width:"100%"}
const m = data.panel;
//const nn = m.brand.itm.keys();
//var brnd = [];

//m.brand.itm.map((i, key) => i.logo.qwn > 0 ? brnd[i.id] = i.logo.doc : null )

//console.log('KEYS', brnd['oven'].src);

if(props.mode === "logos"){
return(
 <div className="infoblock">
   {
    props.notitle? null : <h2>{Base64.decode(m.ttl)}</h2>
   }
  <div className="PicsPanel pat f10">
  {
    m.images.map((i, key) => 
      i.qwn > 0 ?
      <img 
      src={path+i.img.src.slice(0, i.img.src.indexOf('.jpg'))+'_m.jpg'} 
      alt={Base64.decode(i.ttl)}
      title={Base64.decode(i.ttl)}
      key={key}
      />:null)
  }
  </div>
  </div>
)
}

const path = '/pics/';
//const path = '/svg/brands/';

return(
  <div style={{flex:'0 0 Auto'}} className="infoblock">
   {
    props.notitle? null : <h2>{Base64.decode(m.ttl)}</h2>
   }    
  <div className="PicsPanel pat f10">
        {
        m.images.map((i, key) => 
        //nn.map((i, key) => 
          <Link to={'/'+lang+'/produkte'} key={key}>
             {i.qwn > 0 ?
             <Fragment>
              <img 
                src={path+i.img.src.slice(0, i.img.src.indexOf('.jpg'))+'.jpg'} 
                key={key} 
                alt={Base64.decode(i.ttl)} 
                title={Base64.decode(i.ttl)}
              />
              <div>
                {Base64.decode(i.ttl)}
              </div>
              </Fragment>
              : Base64.decode(i.ttl)}
          </Link>)
      }
    </div>
  </div>
);

//==========================================



function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}

function GetDATA(cid, base){
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang+'/');
        form.append('CurID', cid);
        form.append('data', 'get-picspanel'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
    
      setData(data);
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} //===================


