import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {Helmet} from "react-helmet"
import Base64 from "./Base64"
import Loader from "./Loader"

var upid = null;

export default function GetInfoBlock(props){

const dispatch = useDispatch()
const setup = useSelector(state => state.setup);
const bases = useSelector(state => state.navbases.nums);

const [AllIs, setAllIs] = useState({loading:true, error:null});
const [data,setData] = useState({info:[]});

const id = props.blk;
const base = 'info';

useEffect(() => {
  //console.log('GET INFO BLOCK', setup.lang, id);
  GetDATA(id, bases[base]); 
}, [id, props.lang] );

//============================================================

if(AllIs.error){
        return <p>INFO BLOCK: {AllIs.error.message}</p>
    } 

if(AllIs.loading){
        return <Loader/>
} 


const menu = {width:"100%"}
const m = data.info;
let title = Base64.decode(m.title);
let description = Base64.decode(m.metad);
let keywords = Base64.decode(m.metak);

return(
  <>
    {
    m.vmode === 'page' ? <Helmet>
                              <title>{title}</title>
                              <meta name="description" content={description}/>
                              <meta name="keywords" content={keywords}/>
                              <link rel="canonical" href={setup._domen+'/'+setup.lang+'/'+id}/>
                          </Helmet>
    : null
  }
          
    {
      props.ttl?
      <>
          <h2>
            {
              Base64.decode(m.ttl)
            }
          </h2>
      </>
      : null
    }
    { 
      <p dangerouslySetInnerHTML={{ __html: sanitize(Base64.decode(m.data))}}/>
    }
  </>
);

//==========================================

function sanitize(html) {
  var doc = document.createElement('div');
  doc.innerHTML = html;
  return doc.innerHTML;
}


function GetDATA(cid, base){
  setAllIs({loading:true, error:false});
  const form = new FormData()
        form.append('BaseNumber', base);
        form.append('lang', setup.lang+'/');
        form.append('CurID', cid);
        form.append('data', 'get-infoBlock'); //xsl - шаблон 
  const MyInit = { 
              method: 'POST',
              body: form
              };
fetch(setup.domen+setup.folder+'data', MyInit)
  .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.text()+'Something went wrong ...');
      }
  })

  .then(data => {
      setData({info:data.info});
      setAllIs({loading:false, error:false});
      })
  .catch(error => {
      setAllIs({loading:true, error:error});
   });
}

} //===================


