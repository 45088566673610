import React, { useState, useEffect, Fragment } from 'react';
import useMedia from "../js/useMedia"
import {useWindowScroll} from 'react-use'
import { useSelector, useDispatch } from 'react-redux'
import LangSet from '../LANG/LangSet.js'


//import Loader from "./Loader"
import './shapka.css'
import '../css/mob.css'

import {
    Link,
    NavLink
} from "react-router-dom";

export default function Shapka(){
    const dispatch = useDispatch()
    const topnav = useSelector(state => state.topnav);
    const setup = useSelector(state => state.setup);

    let [isMenu, setMenu] = useState(false)
    let [logoClass, setLogoClass] = useState('logo z10');

    let small = useMedia("(max-width:420px)");
    let large = useMedia("(min-width:650px)");
    const {x, y} = useWindowScroll();

    var path ='';
    var qstr = window.location.toString();

    let url = new URL(qstr);
    let ltmp= url.pathname;

    useEffect(() => { 
        if( y>5 ) {setLogoClass('logo z6')} else {setLogoClass('logo z10')}
    }, [y] );

const toggleMenu =() => isMenu ? setMenu(false) : setMenu(true);

const punkts = (cls) => {
  return <Fragment>
            <ul className={cls} onClick={small? toggleMenu : null}>
            {
                topnav.map((i, key) => 
                <Fragment>
                    <li key={key}>
                    <Link to={path = i.id === 'home' ? '/'+setup.lang : '/'+setup.lang+'/'+i.id} 
                        className={path === ltmp ? 'active':''}
                    >
                        {i.ttl}
                    </Link>
                    </li>
                </Fragment>
                )
            }
            </ul>
            {    
                large? null : <LangSet active={setup.lang}/>
            } 
        </Fragment>
      }    
      
return(
    <Fragment>
        {
            large ? <Fragment>
            <div className="NavPenel sticky">
                {
                  punkts('App-header')
                }
                <LangSet active={setup.lang}/>
            </div> 
            
            </Fragment>
            : 
            <div key="nav" className="NavPenel sticky">
                
                <img src="/svg/menu.svg" className="m-top-menu" onClick={toggleMenu} alt="menu"/>
                        {
                        isMenu ? 
                            punkts('Mob-header')
                        :null
                        }
            </div>
        }
        {small? isMenu ? <div key="overlay" className="overlay" onClick={toggleMenu}/>:null:null}
    </Fragment>
)

}