import React, { useState, useEffect, Fragment } from 'react'
import {Helmet} from "react-helmet"
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";


import useMedia from "./js/js/useMedia"
import Base64 from "./js/Base64"


import Shapka from "./js/header/Shapka"
import ShapkaPreloader from "./js/header/ShapkaPreloader"
import GetBrands from "./js/BRANDS/GetBrands"
import PicsPanel from "./js/PicsPanel"
//import Show_BRAND from "./js/BRANDS/Show_BRAND"
import MainPage_DeskTop from "./js/MainPage_DeskTop"
import MainPage_Mobile from "./js/MainPage_Mobile"
import About from "./js/About"
import Firma from "./js/Firma"
import Products from "./js/Products"
import Solutions from "./js/Solutions"
import Contacts from "./js/Contacts"
import Service from "./js/Service"

import GetInfoBlock from "./js/GetInfoBlock"

import './css/App.css';
import './css/index.css';
import './css/logo.css';
import './js/css/effects.css';
import './js/css/footer.css';
import './js/css/styles.css';
import './js/css/infoblock.css';

var rpfx = '';
var rttl = '';
var rman = '';
var title = 'AIR NIC AG';
var renders = 0;
var mainPic = null;


function App(props) {

  const dispatch = useDispatch()
  const setup = useSelector(state => state.setup);
  let large = useMedia("(min-width:650px)");
  let { lang, pid, id } = useParams();

  const [NavIDIs, setNavIDIs] = useState({loading:true, error:false});

  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  let mode = query.get('mode');

  var qstr = window.location.toString();
  let url = new URL(qstr);
  let ltmp= url.pathname.split('/');
  var LANG = ltmp[1];

 useEffect(() => { 
  //ReactGA.initialize('UA-6337752-1');
  //console.log('SETUP_LANG', LANG);
  LoadNavIDs(LANG); 
  if(NavIDIs.loading){ 
  }
  }, [LANG] );

  
if(NavIDIs.error){
  return <p>AppJS: {NavIDIs.error.message}</p>
} 


return (
  <Fragment>
      <Helmet>
      <title>{title}</title>
      </Helmet>
        <div className="App">
          <div className='LogoPlate'><img src="/svg/inlogo.svg" className="logo" alt=""/>
          {
          NavIDIs.loading ? null : <TopTelephones/>
          }
          </div>
          {
            NavIDIs.loading ? 
            <ShapkaPreloader/>
            :
            <Fragment>
              <Shapka/>
                <img src={'/pics/'+mainPic} className='MainPic'/>
              <Routes>
                    <Route exact path="/:lang" element={<RHome/>}/>
                    <Route exact path="/:lang/home" element={<RHome/>}/>
                    <Route exact path="/:lang/firma" element={<RFirma/>}/>
                    <Route exact path="/:lang/produkte" element={<RProducts/>}/>
                    <Route exact path="/:lang/losungen" element={<RSolutions/>}/>
                    <Route exact path="/:lang/kontakt" element={<RContacts/>}/>
                    <Route exact path="/:lang/service" element={<RService/>}/>
                    <Route path="/:lang/about" element={<RAbout/>}/>
              </Routes>
            </Fragment>
          }
        </div>
    </Fragment>
  );

  function RHome(){
    return(
      <Fragment>
        {
        large? <MainPage_DeskTop /> : <MainPage_Mobile lang={LANG} />
        }
          <PicsPanel/>
          <GetBrands />
        <Footer tel={true}/>
      </Fragment>
    )
  }

  function RAbout() {
    return (
      <Fragment>
        <About/><Footer tel={true}/>
      </Fragment>
    )
  }
  function RFirma() {
    return (
      <Fragment>
        <div className="pages">
          <GetInfoBlock blk='firma' ttl="show"/>
        </div>
        <Footer tel={true} lang={LANG}/>
      </Fragment>
    )
  }

  function RProducts() {
    return (
      <Fragment>
        <Products lang={LANG}/>
        <GetBrands />
        <Footer tel={true} lang={LANG}/>
      </Fragment>
    )
  }

  function RSolutions() {
    return (
      <Fragment>
        <Solutions lang={LANG}/>
        <Footer tel={true} lang={LANG}/>
      </Fragment>
    )
  }

  function RContacts() {
    return (
      <Fragment>
        <Contacts lang={LANG}/><Footer tel={true} lang={LANG}/>
      </Fragment>
    )
  }

  function RService() {
    return (
      <Fragment>
        <Service lang={LANG}/><Footer tel={true} lang={LANG}/>
      </Fragment>
    )
  }

  function LoadNavIDs(lang){
    setNavIDIs({ error:false, loading:true });
  const form = new FormData()
        form.append('baseQ', setup.bases);
        form.append('lang', lang+'/');
  const MyInit = { method: 'POST', body: form };
  fetch(setup.domen+setup.folder+'init', MyInit )
    .then(response => {
      if (response.ok) {
        return response.json();
        } else {
          throw new Error(response.text()+'Something went wrong ...');
        }
      })
    .then(data => { 
      rman = data.itm.man;
      rpfx = data.itm.pfx;
      rttl = data.itm.ttl;

      if(rttl !=='none') { title = ''; }
      if(rman !== 'none') { title = rman+' ' }
      if(rttl !=='none') { title = title+rttl; }
      if(rpfx !== 'none') { title= title + ' — '+rpfx }

      let man = data.man.i;
      let mans = [];
      let manid = [];
      let kurs = [];
      let tags = [];
      let ttl = [];
      let bases_id2num = [];
      let bases_id2itm = [];
      mainPic = data.mainpic;
      dispatch({type:'SET_META', 
                payload:{
                  ttl:Base64.decode(data.site.ttl), 
                  dsc:Base64.decode(data.site.dsc), 
                  kwrd:Base64.decode(data.site.kwrd)
                        }
              });

      man.map((i, key) => mans[i.ttl] = i);
      man.map((i, key) => manid[i.id] = i);

      
      dispatch({type:'SET_BRANDS', payload:{ttl:mans, ids:manid}});
      data.navid.map((i, key)=>{ bases_id2num[i.id] = key-1 });
      data.navid.map((i, key)=>{ bases_id2itm[i.id] = i });
      dispatch({type:'SET_NAVBASES', payload:{nums:bases_id2num, itms:bases_id2itm}});
      dispatch({type:'LOAD_NAV_IDS', payload:data.navid});
      dispatch({type:'SET_TOPNAV', payload:data.topnav.mnu});
      dispatch({type:'SET_LANG', payload:lang});

      setNavIDIs({loading:false, error:false });  
      
     })
    .catch(error => { setNavIDIs({ error:error, loading:true }); })
}

function Footer(p) {
  return (
    <div className="footer">
      <GetInfoBlock blk="footer"/>
    </div>
  );
}

function TopTelephones(p) {
  return ( large?
        <Fragment>
             <div className="toptels">
              <div>
                <GetInfoBlock className="toptext" blk="toptext"/>
              </div>
             <GetInfoBlock blk="toptels"/>
            </div>
        </Fragment>
        : 
        null
  );
}


}
export default App;
