
const LOAD_COMMENTS = 'LOAD_COMMENTS';
const SET_COMMENT_LOAD_FUNC = 'SET_COMMENT_LOAD_FUNC';

export default function comments(state, action){

	switch (action.type) {
		case LOAD_COMMENTS:
			return{...state, comments:action.payload };
		case SET_COMMENT_LOAD_FUNC:
			return{...state, comnt_load_func:action.payload };

	default: 
	}
	return state;
};