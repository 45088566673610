const ADD_2_BASKET = 'ADD_2_BASKET';
const SUB_4M_BASKET = 'SUB_4M_BASKET';
const SET_BASKET_SIZE = 'SET_BASKET_SIZE';
const DEL_POSITION = 'DEL_POSITION';
const CLEAR_BASKET = 'CLEAR_BASKET';
const RESET_BQWN = 'RESET_BQWN';


export default function basket(state, action){
    var bsk = new Map();
    var total = 0;
    
    switch (action.type) {

        case ADD_2_BASKET:
            bsk = state.basket;
            let i = action.payload;
            if (bsk.has(i.id)) { 
                bsk.get(i.id).qwn++;
                bsk.get(i.id).total = bsk.get(i.id).qwn * bsk.get(i.id).prc;
            } else {
                bsk.set(i.id, action.payload);
                bsk.get(i.id).total = bsk.get(i.id).qwn * bsk.get(i.id).prc;
            }
            total = 0;
            [...bsk].map((itm)=>{
                //let key = itm[0];
                  let value = itm[1];
                  total = total+value.total;
            });
            return{...state, basket: bsk, btotal:total, bqwn:bsk.size };

		case SUB_4M_BASKET:
            bsk = state.basket;
            let id = action.payload;
            if ( bsk.has(id) && bsk.get(id).qwn > 0 ){
                bsk.get(id).qwn--;
                bsk.get(id).total = bsk.get(id).qwn * bsk.get(id).prc;

                total = 0;
                [...bsk].map((itm)=>{
                   // let key = itm[0];
                    let value = itm[1];
                  total = total+value.total;
                });

                return{...state, basket: bsk, btotal:total };
            } else {
                return state
            }
        case SET_BASKET_SIZE:
            return {...state, bqwn:state.basket.size };

        case DEL_POSITION:
            bsk = state.basket;
            if(bsk.has(action.payload)){
                bsk.delete(action.payload)
            }
            return {...state, basket:bsk, bqwn:bsk.size };

        case CLEAR_BASKET:
            bsk = state.basket;
            bsk.clear();
            return{...state, basket: bsk };
        
        case RESET_BQWN:
                return{...state, bqwn: null };

	default: 
	}
	return state;

};