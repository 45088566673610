import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import {Helmet} from "react-helmet"
import GetInfoBlock from "./GetInfoBlock"

export default function About(props){
    var title = 'О НАС — Дельта Инжиниринг';
    const setup = useSelector(state => state.setup);

    document.title = title;
    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="О компании"/>
          <meta name="keywords" content="about, о нас, о компании, мы"/>
          <link rel="canonical" href={setup._domen+'/about'}/>
         </Helmet>
        <div className="pages">
          <h2 className="itmttl">О НАС</h2>
          <br/><br/>
          <GetInfoBlock blk="about"/>
        </div>
      </Fragment>
    );

}