import React, { useState, useEffect, Fragment } from 'react'
import GetInfoBlock from "./GetInfoBlock"


export default function MainPage_Mobile(props){
    return (
    <div className='pages'>
        <GetInfoBlock blk='home' ttl=""/>
    </div>
    )
}