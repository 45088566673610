import {
	LOAD_NAV_IDS,
	LOAD_MANUFACTUR,
} from '../ActionTypes'

const SET_NAVBASES = 'SET_NAVBASES';
const SET_BRANDS = 'SET_BRANDS';
const SET_KURS = 'SET_KURS';
const SET_TAGS = 'SET_TAGS';
const SMODE = 'SMODE';
const SET_META = 'SET_META';
const SET_TOPNAV = 'SET_TOPNAV';
const SET_LANG = 'SET_LANG';

export default function site(state, action){
//console.log('REDUX:',action.type,' = ', action.payload);
	switch (action.type) {
		
		case SET_LANG:
			return{...state, setup:{...state.setup, lang:action.payload}};

		case LOAD_NAV_IDS:
			return{...state, setup:{...state.setup, navid:action.payload} };

		case LOAD_MANUFACTUR:
			return{...state, 
							manufactura:action.payload.manufactura, 
							valuta: action.payload.valuta };
		case SET_META:
			return{...state, meta:action.payload};
			
		case SET_NAVBASES:
			return{...state, navbases:action.payload };

		case SET_BRANDS:
			return{...state, brands:action.payload };

		case SET_TAGS:
				return{...state, tags:action.payload };

			case SET_KURS:
				return{...state, kurs:action.payload };
				
			case SET_TOPNAV:
					return{...state, topnav:action.payload };		

			case SMODE:
				return{...state, setup:{...state.setup, smode:action.payload}};

	default: 
	}
	return state;
};