import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import "./css/form.css"

export default function FormProducts(props){

    var labels = [];
    var err_labels = [];
    var msg = []; 
    
    msg['ru'] = 'Спасибо! Мы свяжемся с вами в ближайшее время.'
    msg['en'] = 'Thank you for your message! We will contact you!'
    msg['de'] = 'Vielen Dank für Ihre Nachricht! Wir melden uns bei Ihnen!'

    err_labels['ru'] = {
      country          : 'Укажите страну!',
      companyname      : 'Введите название компании!',
      address          : 'Укажите ваш адрес!',
      emailaddress     : 'Обязательно к заполнению!',
      mobilephone      : 'Обязательно к заполнению!',
      authorizedperson : 'Обязательно к заполнению!',
      brand            : 'Обязательно к заполнению!',
      technicaldata    : 'Обязательно к заполнению!',
      }

    labels['ru'] = {
      title:'ФОРМА ЗАПРОСА',
      country          : 'Страна: *',
      companyname      : 'Название компании: *',
      address          : 'Адрес: *',
      emailaddress     : 'Email *',
      mobilephone      : 'Номер мобильного телефона: *',
      authorizedperson : 'Ответсвенное лицо: *',
      brand            : 'Интересующий производитель: *',
      technicaldata    : 'Подробности и коментарии: *',
      submit           : 'Отправить'
      }

    labels['uz'] = {
        title:'ФОРМА ЗАПРОСА',
        country          : 'Страна: *',
        companyname      : 'Название компании: *',
        address          : 'Адрес: *',
        emailaddress     : 'Email *',
        mobilephone      : 'Номер мобильного телефона: *',
        authorizedperson : 'Ответсвенное лицо: *',
        brand            : 'Интересующий производитель: *',
        technicaldata    : 'Подробности и коментарии: *',
        submit           : 'Отправить'
        }

    labels['de'] = {            
      title:'ANFRAGEFORMULAR',
      country          : 'Land: *',
      companyname      : 'Firmenname: *',
      address          : 'Anschrift: *',
      emailaddress     : 'Email *',
      mobilephone      : 'Handy: *',
      authorizedperson : 'Berechtigte Person *',
      brand            : 'Bitte wählen Sie Hersteller *',
      technicaldata    : 'Bitte geben Sie weitere technische Informationen über Produkt *',
      submit           : 'Senden'
    }
  
    labels['en'] = {
      title:'REQUEST FORM',
      country          : 'Country: *',
      companyname      : 'Company Name: *',
      address          : 'Address: *',
      emailaddress     : 'E-mail *',
      mobilephone      : 'Mobile: *',
      authorizedperson : 'Contact Person *',
      brand            : 'BRAND *',
      technicaldata    : 'Tech information/ comments *',
      submit           : 'Submit'
    }    

    const [lang, setLang] = useState(props.lang);
    const brand =[
                    { value: 'ABB', label: 'ABB' },
                    { value: 'AEG', label: 'AEG' },
                    { value: 'AUMA', label: 'AUMA' },
                    { value: 'BURKERT', label: 'BURKERT' },
                    { value: 'EMERSON', label: 'EMERSON' },
                    { value: 'ENDRESS', label: 'ENDRESS' },
                    { value: 'FESTO', label: 'FESTO' },
                    { value: 'GEMU', label: 'GEMU' },
                    { value: 'HEINRICHS', label: 'HEINRICHS' },
                    { value: 'HYCONTROL', label: 'HYCONTROL' },
                    { value: 'INTERAPP', label: 'INTERAPP' },
                    { value: 'KELLER', label: 'KELLER' },
                    { value: 'MEYLE', label: 'MEYLE' },
                    { value: 'MJK', label: 'MJK' },
                    { value: 'MOELLER', label: 'MOELLER' },
                    { value: 'NEGELE', label: 'NEGELE' },
                    { value: 'PEPPERL FUCHS', label: 'PEPPERL FUCHS' },
                    { value: 'PHOENIX CONTACT', label: 'PHOENIX CONTACT' },
                    { value: 'SCHNEIDER ELECTRIC', label: 'SCHNEIDER ELECTRIC' },
                    { value: 'SICK', label: 'SICK' },
                    { value: 'SIEMENS', label: 'SIEMENS' },
                    { value: 'YOKOGAWA', label: 'YOKOGAWA' },
                    { value: 'VEGA', label: 'VEGA' }
    ];
    const [formData, setFormData] = useState({
                                                country          : '',
                                                companyname      : '',
                                                address          : '',
                                                emailaddress     : '',
                                                mobilephone      : '',
                                                authorizedperson : '',
                                                brand            : '',
                                                technicaldata    : ''
                                              });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const setup = useSelector(state => state.setup);
    
    const [AllIs, setAllIs] = useState({loading:false, error:null, open:false, done:false});
    const [selBrandOption, setSelBrandOption] = useState(null);        

    const open = () => setAllIs({loading:false, error:null, open:true}) 
    const animatedComponents = makeAnimated();

   const handleOnChange = (ev) => {
      const { value, name } = ev.target
      setFormData({ ...formData, [name] : value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.brand = selBrandOption;
    setFormErrors(validate(formData));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      SaveDATA();
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.country) {
      errors.country = err_labels[lang].country;
    }

    if (!values.companyname) {
      errors.companyname = err_labels[lang].companyname;
    }

    if (!values.address) {
      errors.address = err_labels[lang].address;
    }

    if (!values.mobilephone) {
      errors.mobilephone = err_labels[lang].mobilephone;
    }

    if (!values.authorizedperson) {
      errors.authorizedperson = err_labels[lang].authorizedperson;
    }

    if (!values.brand) {
      errors.brand = err_labels[lang].brand;
    }

    if (!values.technicaldata) {
      errors.technicaldata = err_labels[lang].technicaldata;
    }


    if (!values.emailaddress) {
      errors.emailaddress = err_labels[lang].emailaddress;
    } else if (!regex.test(values.emailaddress)) {
      errors.emailaddress = "This is not a valid email format!";
    }

    //if (!values.password) {
    //  errors.password = "Password is required";
    //} else if (values.password.length < 4) {
    //  errors.password = "Password must be more than 4 characters";
    //} else if (values.password.length > 10) {
    //  errors.password = "Password cannot exceed more than 10 characters";
    //}

    return errors;
  };

    return (
      <Fragment>
          {
          AllIs.done?
            <p className='f16'>{msg[lang]}</p>
              :
              <>
                <h2>{labels[lang].title}</h2>
                {
                  AllIs.open?
                      <form name="products" onSubmit={handleSubmit} className="form">

                          <label htmlFor="country">{labels[lang].country}</label>
                          <input type="text" name="country" value={formData.country} size="40" required onChange={handleOnChange}/>
                          <p>{formErrors.country}</p>

                          <label htmlFor="companyname">{labels[lang].companyname}</label>
                          <input type="text" name="companyname" value={formData.companyname} size="40" required onChange={handleOnChange}/>
                          <p>{formErrors.companyname}</p>

                          <label htmlFor="address">{labels[lang].address}</label>
                          <input type="text" name="address" value={formData.address} size="40" required onChange={handleOnChange}/>
                          <p>{formErrors.address}</p>

                          <label htmlFor="emailaddress">{labels[lang].emailaddress}</label>
                          <input type="email" name="emailaddress" value={formData.emailaddress} size="40" required onChange={handleOnChange}/>
                          <p>{formErrors.emailaddress}</p>

                          <label htmlFor="mobilephone">{labels[lang].mobilephone}</label>
                          <input type="text" name="mobilephone" value={formData.mobilephone} size="40" onChange={handleOnChange} required/>
                          <p>{formErrors.mobilephone}</p>

                          <label htmlFor="authorizedperson">{labels[lang].authorizedperson}</label>
                          <input type="text" name="authorizedperson" value={formData.authorizedperson} size="40" required onChange={handleOnChange}/>
                          <p>{formErrors.authorizedperson}</p>

                          <label htmlFor="brand">{labels[lang].brand}</label>
                              <Select
                                defaultValue={selBrandOption}
                                onChange={setSelBrandOption}
                                components={animatedComponents}
                                isMulti
                                options={brand}
                              />                              
                            <p>{formErrors.brand}</p>

                          <label htmlFor="technicaldata">{labels[lang].technicaldata}</label>
                          <textarea name="technicaldata" value={formData.technicaldata} cols="40" rows="5" required onChange={handleOnChange}/>
                          <p>{formErrors.technicaldata}</p>

                          <input type="submit" value={labels[lang].submit}/>
                      </form>
                      :
                      <img src='/svg/form.svg' onClick={open} className="formPic hand"/>
                }
              </>

          }
      </Fragment>        
    );

    function SaveDATA(){
        //ev.preventDefault()
        formData.brand = selBrandOption.map((k) => {return k.value}).join(",");

        const form = new FormData()
              form.append('form', props.page);
              form.append('lang', lang);
              form.append('country', formData.country);
              form.append('companyname', formData.companyname);
              form.append('address', formData.address);
              form.append('emailaddress', formData.emailaddress);
              form.append('mobilephone', formData.mobilephone);
              form.append('authorizedperson', formData.authorizedperson);
              form.append('brand', formData.brand);
              form.append('technicaldata', formData.technicaldata);
              
        const MyInit = { 
                    method: 'POST',
                    body: form
                    };
    
      //console.log('CHKD',Form.elements.transformhtml.checked);                
      fetch(setup.domen+setup.folder+'add_form_data.php', MyInit)
        .then(response => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error(response.text()+'Something went wrong ...');
            }
        })
        .then(data => {
            //setAllIs({...AllIs, loading:true});
            setAllIs({...AllIs, done:true})
        })
        .catch(error => {
            setAllIs({...AllIs, error:error});
        });
      }
}