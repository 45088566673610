import React, { Fragment, useState, useEffect } from 'react';
import GetInfoBlock from "./GetInfoBlock"
import FormProducts from './FormProducts'

export default function Products(props){
    
    var info_id = [];

    info_id['de'] = 'produkte';
    info_id['en'] = 'produkte';
    info_id['ru'] = 'produkte';
    info_id['uz'] = 'produkte';
    
  
    let lang = props.lang;
 
  return (
      <Fragment>
        <div className="pages">
          <GetInfoBlock blk={info_id[lang]} ttl="show" setheaders='on'/>
          <FormProducts lang={lang} page='PRODUKTE'/>
        </div>
      </Fragment>        
    );

}