import {

	SET_VMODE,

	UPDATE_VCONFIG_CONFIG,
	UPDATE_VCONFIG_VMODE,
	
	SET_CUR_ID,
	SET_SELECTED_ID,
	SET_MENU_PTR_ID,
	SET_MENU_PUNKT_ID_TAG,
	SET_MENU_PUNKT_PBL_TAG,
	SET_MENU_PUNKT_VMODE_TAG,
	SET_MENU_PUNKT_TTL_TAG,
	SET_MENU_FORSE_RELOAD_FLAG,
	
	SET_MENU_LOAD_FUNC,

	SET_MENU_LIST,
	
	SET_MENU_PTR,

	SET_DATA_STRUCT_ENTERING_PTR,

	SET_ACTION,

			} from '../ActionTypes'

const SET_COUNT = 'SET_COUNT';
const RESET_COUNT = 'RESET_COUNT';


export default function menu(state, action){
//console.log('REDUX:',action.type,' = ', action.payload);
	switch (action.type) {

		case UPDATE_VCONFIG_CONFIG:
			return{...state, vConfig:{...state.vConfig, config:action.payload} };

		case UPDATE_VCONFIG_VMODE:
			return{...state, vConfig:{...state.vConfig, vmode:action.payload} };

		case SET_COUNT:
			return{...state, count:state.count + action.payload };
			
		case RESET_COUNT:
			return{...state, count:0 };

		case SET_MENU_LIST:
			let mn = state.mnulist;
			mn[action.payload.idx] = action.payload.mnulist;
			return{...state, mnulist:mn };

		case SET_MENU_LOAD_FUNC:
			return{...state, funcMenuLoad:action.payload };

		case SET_DATA_STRUCT_ENTERING_PTR:
			return{...state, mnu:{...state.mnu, dataStructEnteringPTR:action.payload } };

		case SET_MENU_PTR:
			return{...state, mnu:{...state.mnu, menuPTR:action.payload }};

		case SET_CUR_ID:
			return{...state, curid:action.payload };

		case SET_MENU_PTR_ID:
			return{...state, mnu:{...state.mnu, menuPTR_ID:action.payload }};

		case SET_MENU_FORSE_RELOAD_FLAG:
			return{...state, mFRld: action.payload };
			
		case SET_VMODE:
			return{...state, mnu:{...state.mnu, vmode:action.payload }};

		case SET_SELECTED_ID:
			const selMenuIDs = state.mnu.selectedID;
    		selMenuIDs[state.mnu.menuPTR] = action.payload;
			return{...state, mnu:{...state.mnu, selectedID: selMenuIDs}};	

		case SET_MENU_PUNKT_PBL_TAG:
			let mnlst = state.mnulist;
    		mnlst[action.payload.idx].pbl = action.payload.pbl;
			return{...state, 
				mnulist:mnlst,
				mFRld:action.payload.pbl
			};
		
		case SET_MENU_PUNKT_ID_TAG:
			let mnilst = state.mnulist;
    		mnilst[action.payload.idx].id = action.payload.id;
			return{...state, 
				mnulist:mnilst,
				mFRld:'reload epta!'
			};
		
		case SET_MENU_PUNKT_TTL_TAG:
			let mntlst = state.mnulist;
    		mntlst[action.payload.idx].ttl = action.payload.ttl;
			return{...state, 
					mnulist:mntlst,
					mFRld:action.payload.ttl 
			};


		case SET_MENU_PUNKT_VMODE_TAG:
			let mlst = state.mnulist;
    		mlst[action.payload.idx].vmode = action.payload.vmode;
    		mlst[action.payload.idx].depth = action.payload.depth;
			return{...state, 
				mnulist:mlst,
			};			
	}
	return state;
};