import {
	SET_NO_PNG,
	LOAD_CONTENT,
	SET_CONTENT_IS_PUBLISHED,
	TOGLE_CONTENT_ZAK_TAG,
	SET_CONTENT_IMGES,
	SET_CONTENT_HTML_TTL,
	SET_IMAGE_LIST,

	} from '../ActionTypes'

const SET_CONTENT_PFX = 'SET_CONTENT_PFX';

const SET_CONTENT_MAN = 'SET_CONTENT_MAN';
const SET_CONTENT_LOG = 'SET_CONTENT_LOG';

const SET_CONTENT_TTL = 'SET_CONTENT_TTL';
const SET_CONTENT_PRC = 'SET_CONTENT_TTL';
const SET_CONTENT_D = 'SET_CONTENT_D'; //Скидка

const SET_CONTENT_DSC_TXT = 'SET_CONTENT_DSC_TXT';
const SET_CONTENT_SM_DSC = 'SET_CONTENT_SM_DSC';
const SET_CONTENT_DSC_TH = 'SET_CONTENT_DSC_TH';  
const TOGLE_CONTENT_SNYAT_TAG = 'TOGLE_CONTENT_SNYAT_TAG';

const content = (state, action) => {
	switch (action.type) {
	
		case LOAD_CONTENT:
			return{...state, content:action.payload };	
	
		case TOGLE_CONTENT_ZAK_TAG:
			let zak = state.content;
    		zak.zak = action.payload;
			return{...state, content:zak};

		case TOGLE_CONTENT_SNYAT_TAG:
			let snyat = state.content;
    		snyat.snyat = action.payload;
			return{...state, content:snyat};

		case SET_CONTENT_IS_PUBLISHED:
			let cont = state.content;
    		cont.pbl = action.payload;
			return{...state, content:cont};

		case SET_CONTENT_IMGES:
			return{...state, content:{...state.content, imges:action.payload }};

		case SET_CONTENT_MAN:
			return{...state, content:{...state.content, man:action.payload }};

		case SET_CONTENT_LOG:
			return{...state, content:{...state.content, log:action.payload }};

		case SET_CONTENT_HTML_TTL:
			return{...state, content:{...state.content, html_ttl:action.payload }};	

		case SET_CONTENT_TTL:
			return{...state, content:{...state.content, t:action.payload }};	

		case SET_CONTENT_PFX:
			return{...state, content:{...state.content, pfx:action.payload }};

		case SET_CONTENT_PRC:
			return{...state, content:{...state.content, prc:action.payload }};

		case SET_CONTENT_D:
			return{...state, content:{...state.content, d:action.payload }};

		case SET_CONTENT_DSC_TXT:
			return{...state, content:{...state.content, dsc_txt:action.payload }};

		case SET_CONTENT_SM_DSC:
			return{...state, content:{...state.content, sm_dsc:action.payload }};

		case SET_CONTENT_DSC_TH:
			return{...state, content:{...state.content, dsc_th:action.payload }};

		case SET_NO_PNG:
			return{...state, content:{...state.content, imges:'no.png'}};	

		case SET_IMAGE_LIST:
			return{...state, content:{...state.content, imgs:action.payload} };	

	}
	return state;
}

export default content;