import React, { Fragment, useState, useEffect } from 'react';
import GetInfoBlock from "./GetInfoBlock"
import FormProducts from './FormProducts'

export default function Contacts(props){

    var info_id = [];

    info_id['de'] = 'kontakt';
    info_id['en'] = 'kontakt';
    info_id['ru'] = 'kontakt';
    info_id['uz'] = 'kontakt';
    
    let lang = props.lang;

    return (
      <Fragment>
        <div className="pages">
          <GetInfoBlock blk={info_id[lang]} ttl="show"/>
          <FormProducts lang={lang} page='KONTAKT'/>
        </div>
      </Fragment>        
    );
  
}