import React, { useState, useEffect, Fragment } from 'react';
import useMedia from "../js/useMedia"
import {useWindowScroll} from 'react-use'


//import Loader from "./Loader"
import './sh_preloader.css';

import {
    Link,
} from "react-router-dom";

export default function ShapkaPreloader(){

    let [isMenu, setMenu] = useState(false)
    let [logoClass, setLogoClass] = useState('logo z10');

    let small = useMedia("(max-width:420px)");
    let large = useMedia("(min-width:650px)");
    const {x, y} = useWindowScroll();

    useEffect(() => { 
        if( y>5 ) {setLogoClass('logo z6')} else {setLogoClass('logo z10')}
    }, [y] );

const toggleMenu =() => isMenu ? setMenu(false) : setMenu(true);

const punkts = (cls) => {
                    return <ul className={cls} onClick={small? toggleMenu : null}>
                 
                           </ul>
                        }    
      
return(
    <Fragment>
        {
            large ? <Fragment>
            <div className="NavPenel demo sticky">
                {
                    punkts('App-header')
                }
            </div> 
            </Fragment>
            : 
            <div key="nav" className="NavPenel sticky">
                <img src="/svg/menu.svg" className="m-top-menu" onClick={toggleMenu} alt="меню"/>
                        {
                        isMenu ? 
                            punkts('Mob-header')
                        :null
                        }
            </div>
        }
        {small? isMenu ? <div key="overlay" className="overlay" onClick={toggleMenu}/>:null:null}
    </Fragment>
)

}